import { IClient, IMultiCallItem, IStatus } from "@/lib/interfaces";

import { ObjectTypeAPI, omniApi, passParams } from "../../omniApi";

export interface IAPISimpleResponse {
  status: IStatus;
}

export interface IAgnosticGetQuery {
  url: string;
  params?: {
    [key: string]:
      | string
      | number
      | Array<{ field: string; value: string; operator?: string }>
      | string[];
    fields: string[];
  };
}

export interface IAgnosticDeleteQuery {
  url: string;
  id: string;
  suffix?: string;
  body?: {
    [key: string]: string | number;
  };
}

export interface IAgnosticPostQuery {
  url?: string;
  body?: {
    [key: string]: string | number;
  };
  method?: "POST" | "PUT" | "DELETE";
}

export const agnosticAPI = omniApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getUser: builder.query<any, string>({
      query: (id) => ({
        url: `/user/${id}`,
        method: "get",
      }),
    }),
    //GET ALL, without tagging for refetch
    agnosticUntaggedGet: builder.query<any, IAgnosticGetQuery>({
      query: ({ url, params }) => ({
        url:
          !params || Object.values(params).length === 0
            ? url
            : `${url}?data=${passParams({ params })}`,
        method: "get",
      }),
    }),
    //GET ALL
    agnosticGet: builder.query<any, IAgnosticGetQuery>({
      query: ({ url, params }) => ({
        url:
          !params || Object.values(params).length === 0
            ? url
            : `${url}?data=${passParams({ params })}`,
        method: "get",
      }),
      providesTags: (result) => {
        const tags = result?.tagsProvided?.map((tag) => {
          return { type: tag };
        });
        return tags ? tags : [{ type: ObjectTypeAPI.Generic }];
      },
    }),
    //DELETE
    agnosticDelete: builder.mutation<any, IAgnosticDeleteQuery>({
      query: ({ url, id, suffix = "", body }) => ({
        url: suffix == "" ? `${url}/${id}` : `${url}/${id}${suffix}`,
        method: "delete",
        body,
      }),
      invalidatesTags: (result) => {
        const tags = result?.tagsInvalidated?.map((tag) => {
          return { type: tag };
        });
        return tags ? tags : [{ type: ObjectTypeAPI.Generic }];
      },
    }),

    //Post
    agnosticPost: builder.mutation<any, IAgnosticPostQuery>({
      query: ({ url, body, method }) => ({
        body,
        url,
        method: method || "post", //if method is not provided, default to post, so it's post or put if passed
      }),
      //optimistic update
      // async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
      //   const patchResult = dispatch(
      //     agnosticAPI.util.updateQueryData('agnosticPost', undefined, (draft) => {
      //       Object.assign(draft, patch)
      //     })
      //   )
      //   try {
      //     await queryFulfilled
      //   } catch {
      //     patchResult.undo()
      //   }
      // },
      invalidatesTags: (result) => {
        const tags = result?.tagsInvalidated?.map((tag) => {
          return { type: tag };
        });
        return tags ? tags : [{ type: ObjectTypeAPI.Generic }];
      },
    }),
    agnosticPostMultiCall: builder.mutation<
      any,
      IAgnosticPostQuery & { calls: IMultiCallItem[] }
    >({
      query: ({ method, calls }) => ({
        body: {
          calls,
        },
        url: `${process.env["NEXT_PUBLIC_SERVER_URL"]}/rest/multicall`,
        method: method || "post",
      }),
      invalidatesTags: (result) => {
        const tags = result?.tagsInvalidated?.map((tag) => {
          return { type: tag };
        });
        return tags ? tags : [{ type: ObjectTypeAPI.Generic }];
      },
      providesTags: (result) => {
        const tags = result?.tagsProvided?.map((tag) => {
          return { type: tag };
        });
        return tags ? tags : [{ type: ObjectTypeAPI.Generic }];
      },
    }),
  }),
});

export const {
  useAgnosticPostMultiCallMutation,
  useAgnosticUntaggedGetQuery,
  useLazyAgnosticUntaggedGetQuery,
  useAgnosticGetQuery,
  useLazyAgnosticGetQuery,
  useAgnosticPostMutation,
  useAgnosticDeleteMutation,
} = agnosticAPI;
