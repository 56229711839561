import { useEffect, useState, type Dispatch, type SetStateAction } from "react";

import { getChakraTheme, getClientByHostname } from "@/lib/chakra/theme";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import { setHostname } from "@/lib/reducers/app";
import { NextPageWithLayout } from "@/lib/types";
import { ChakraProvider } from "@chakra-ui/react";
import { NextComponentType, NextPageContext } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import { ThemeContext } from "pages/_app";

import { AppColorsWrapper } from "./AppColorsWrapper";

type Props = {
  pageProps: any;
  Component: NextComponentType<NextPageContext, any, any> & NextPageWithLayout;
  getLayout: (page: React.ReactElement) => React.ReactNode;
};

export default function AppWrapper({ pageProps, Component, getLayout }: Props) {
  const dispatch = useAppDispatch();
  const { hostname } = useAppSelector((state) => state.app);
  const [favicon, setFavicon] = useState<string>("/omniFavicon.png");
  const [activeTheme, setActiveTheme] = useState<
    Record<string, any> | undefined
  >(undefined);

  useEffect(() => {
    if (typeof window !== "undefined" && hostname === "") {
      dispatch(setHostname({ hostname: window.location.host }));
    }
  }, [dispatch, hostname]);

  useEffect(() => {
    if (activeTheme === undefined && hostname !== "") {
      setActiveTheme(getChakraTheme(hostname));
      const clientType = getClientByHostname(hostname);
      if (clientType === "OMNI") setFavicon("omniFavicon.png");
      else if (clientType === "ALEC") setFavicon("alecFavicon.png");
    }
  }, [activeTheme, hostname]);
  if (hostname === "" || favicon === "") return <></>;

  return (
    <>
      <Head>
        <link
          rel="shortcut icon"
          href={`/static/${favicon}`}
          type="image/x-icon"
        />
      </Head>
      <ThemeContext.Provider value={{ activeTheme, setActiveTheme }}>
        <ChakraProvider theme={activeTheme}>
          <AppColorsWrapper>
            {getLayout(<Component {...pageProps} />)}
          </AppColorsWrapper>
        </ChakraProvider>
      </ThemeContext.Provider>
    </>
  );
}
