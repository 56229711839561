import { useEffect } from "react";

import { getClientByHostname } from "@/lib/chakra/theme";
import { useAppSelector } from "@/lib/hooks";
import { useColorMode } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
};

export const AppColorsWrapper = ({ children }: Props) => {
  const { setColorMode } = useColorMode();
  const { hostname } = useAppSelector((state) => state.app);

  useEffect(() => {
    const clientType = getClientByHostname(hostname);
    if (clientType === "ALEC") {
      setColorMode("dark");
    }
  }, [hostname, setColorMode]);

  return children;
};
