import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { NextRouter } from "next/router";

import { IMenu } from "../interfaces";
import { IMenuType, menuMap } from "../maps";

export interface AppSliceState {
  hostname: string;
  clearSelectedRows: boolean;
  highContrast: boolean;
  dateFormat: string;
  activeTopMenu: IMenu | undefined;
  menuItems: IMenuType;
  stickyTable: boolean;
  pageSize: number;
  offset: number;
}

type AppType = {
  highContrast: boolean;
  dateFormat: string;
  activeTopMenu: IMenu | undefined;
  menuItems: IMenuType;
  stickyTable: boolean;
  clearSelectedRows: boolean;
  pageSize: number;
  offset: number;
  hostname: string;
};

const initialState: AppType = {
  hostname: "",
  clearSelectedRows: false,
  pageSize: 0,
  offset: 0,
  dateFormat: "dd/MM/y",
  highContrast: false,
  stickyTable: true,
  activeTopMenu: undefined,
  menuItems: {
    mainItem: menuMap().mainItem,
    staticItems: menuMap().staticItems,
    moduleItems: menuMap().moduleItems,
    buildingItems: menuMap().buildingItems,
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setHostname(
      state: AppSliceState,
      action: PayloadAction<{ hostname: string }>
    ) {
      state.hostname = action.payload.hostname;
    },
    setTablePage(
      state: AppSliceState,
      action: PayloadAction<{ pageSize: number; offset: number }>
    ) {
      state.pageSize = action.payload.pageSize;
      state.offset = action.payload.offset;
    },
    setDateFormat(
      state: AppSliceState,
      action: PayloadAction<{ dateFormat: string }>
    ) {
      state.dateFormat = action.payload.dateFormat;
    },
    setClearSelectedRows(
      state: AppSliceState,
      action: PayloadAction<{ clearSelectedRows: boolean }>
    ) {
      state.clearSelectedRows = action.payload.clearSelectedRows;
    },
    setHighContrast(
      state: AppSliceState,
      action: PayloadAction<{ highContrast: boolean }>
    ) {
      state.highContrast = action.payload.highContrast;
    },
    // setStickyTable(
    //   state: AppSliceState,
    //   action: PayloadAction<{ stickyTable: boolean }>
    // ) {
    //   state.stickyTable = action.payload.stickyTable;
    // },
    setBuildingsMenuItems(
      state: AppSliceState,
      action: PayloadAction<{ buildings: Array<IMenu> }>
    ) {
      state.menuItems.buildingItems[0].items = action.payload.buildings;
    },
    setModuleMenuItems(
      state: AppSliceState,
      action: PayloadAction<{ modules: Array<IMenu> }>
    ) {
      state.menuItems.moduleItems[0].items = action.payload.modules;
    },
    appLogout: () => initialState,

    toggleSecondMenu(
      state: AppSliceState,
      action: PayloadAction<{ href: string }>
    ) {
      const { href } = action.payload;

      Object.values(state.menuItems).map((menuType) => {
        menuType.map((topItem) => {
          topItem.items?.map((secondItem) => {
            if (href === secondItem.href) {
              secondItem.open = !secondItem.open;
            }
          });
        });
      });
    },
    setActiveTopMenuItem(
      state: AppSliceState,
      action: PayloadAction<{ activeItem: IMenu }>
    ) {
      state.activeTopMenu = action.payload.activeItem;
    },
    initActiveTopMenuItem(
      state: AppSliceState,
      action: PayloadAction<{ router: NextRouter }>
    ) {
      const { router } = action.payload;

      const dynamicLink = router.pathname.includes("[");

      Object.values(state.menuItems).map((menuType) => {
        menuType.map((topItem) => {
          if (
            topItem.href === router.pathname &&
            state.activeTopMenu === undefined
          ) {
            state.activeTopMenu = topItem;
          }

          topItem.items?.map((secondItem) => {
            if (
              router.pathname === secondItem.href &&
              state.activeTopMenu === undefined
            ) {
              state.activeTopMenu = topItem;
            }

            if (dynamicLink) {
              if (
                router.pathname.includes(secondItem.href) &&
                state.activeTopMenu === undefined
              ) {
                state.activeTopMenu = topItem;
              }
            }

            secondItem.items?.map((thirdItem) => {
              if (
                router.pathname === thirdItem.href &&
                state.activeTopMenu === undefined
              ) {
                state.activeTopMenu = topItem;
                secondItem.open = true;
              }

              if (dynamicLink) {
                if (
                  thirdItem.href.includes(router.pathname.slice(0, -5)) &&
                  state.activeTopMenu === undefined
                ) {
                  state.activeTopMenu = topItem;
                  secondItem.open = true;
                }
              }
            });
          });
        });
      });
    },
  },
});

export const {
  setModuleMenuItems,
  setBuildingsMenuItems,
  appLogout,
  setActiveTopMenuItem,
  initActiveTopMenuItem,
  toggleSecondMenu,
  // setStickyTable,
  setHighContrast,
  setDateFormat,
  setTablePage,
  setClearSelectedRows,
  setHostname,
} = appSlice.actions;

export default appSlice.reducer;
