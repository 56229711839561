import {
  theme as base,
  extendTheme,
  withDefaultVariant,
} from "@chakra-ui/react";

type ClientType = "OMNI" | "ALEC";

export const clientUrlParts: Record<ClientType, string[]> = {
  OMNI: ["omni-41", "omni2", "localhost"],
  ALEC: ["alec"],
};

export const ACCENT_COLORS: Record<ClientType, string> = {
  OMNI: "#FF7500",
  ALEC: "#a5192e",
  // ALEC: "#DC2E2B",
};
export const INIT_COLOR_MODE: Record<ClientType, string> = {
  OMNI: "system",
  ALEC: "dark",
};

export function getClientByHostname(hostname: string): ClientType {
  let clientType: ClientType | undefined;

  Object.keys(clientUrlParts).forEach((key) => {
    if (
      clientUrlParts[key as ClientType].some((part) => hostname.includes(part))
    ) {
      clientType = key as ClientType;
    }
  });

  return clientType as ClientType;
}

export function getChakraTheme(hostname: string) {
  const clientType = getClientByHostname(hostname);
  const accentColor = ACCENT_COLORS[clientType];
  const initialColorMode = INIT_COLOR_MODE[clientType];

  return extendTheme(
    {
      initialColorMode,
      colors: {
        accentColor,
        darkBG: "#111E34",
        fieldBgLight: "#F8FAFC",
        fieldBgDark: "#040016",
        lightBorder: "#1B1B36",
        darkBorder: "#78789C",
      },
      // fonts: {
      //   heading: `Inter, ${base.fonts.heading}`,
      //   body: `Inter, ${base.fonts.body}`,
      // },
    },
    withDefaultVariant({
      variant: "filled",
      components: ["Input"],
    })
    // withDefaultColorScheme({ colorScheme: "accentColor" })
  );
}

const theme = extendTheme(
  {
    initialColorMode: "system",
    colors: {
      darkBG: "#111E34",
      fieldBgLight: "#F8FAFC",
      fieldBgDark: "#040016",
      lightBorder: "#1B1B36",
      darkBorder: "#78789C",
    },
    fonts: {
      heading: `Inter, ${base.fonts.heading}`,
      body: `Inter, ${base.fonts.body}`,
    },
    components: {
      // Button
    },
  },
  withDefaultVariant({
    variant: "filled",
    components: ["Input"],
  })
);

export default theme;
