import { useColorModeValue } from "@chakra-ui/react";

export function BoxBorder() {
  return useColorModeValue("gray.200", "#111E34");
  // return useColorModeValue('gray.200', 'gray.700');
}

export function ContentBackground() {
  return useColorModeValue("#fff", "#040016");
}

export function BorderColor() {
  return useColorModeValue("#EFEFEF", "#111E34");
}
export function BorderColorReactSelect() {
  return useColorModeValue("#EFEFEF", "#EFEFEF50");
}

export function DefaultColor() {
  return useColorModeValue("#EFEFEF", "darkBG");
}

export function WhiteDarkColor() {
  return useColorModeValue("#ffffff", "darkBG");
}
export function DarkWhiteColor() {
  return useColorModeValue("#1A1C1E", "#ffffff");
}
export function HoverBg() {
  return useColorModeValue("#EFEFEF", "#111E34");
}
export function ActiveModeColor() {
  return useColorModeValue("#1A1C1E", "#ffffff");
}

export function AssetHover() {
  return useColorModeValue("#FAF089", "#744210");
}

export function DefaultTextColor() {
  return useColorModeValue("darkBG", "#FFFFFF");
}

export function HeaderBG() {
  return useColorModeValue("#FDFDFD", "#001129");
}
export function BorderColorHC() {
  return useColorModeValue("#1B1B36", "#78789C");
}

export function PropsPickerBG() {
  return useColorModeValue("#868C8F", "#868C8F");
}

export function DataGridTh() {
  return useColorModeValue("#EFEFEF", "#1A1C1E");
}

export function DataGridTextFilter() {
  return useColorModeValue("#fff", "#111E34");
  return useColorModeValue("#fff", "#272A30");
}
export function DataGridTdBorder() {
  return useColorModeValue("#EFEFEF", "#111E34");
  return useColorModeValue("#EFEFEF", "#272A30");
}

export function DataGridPaginationColors() {
  return useColorModeValue("#1A1C1E", "#ffffff");
}

export function DataGridActiveRow() {
  return useColorModeValue("gray.800", "gray.800");
}

export function InputColor() {
  return useColorModeValue("#EFEFEF", "#1A1C1E");
}
export function ModePickerColors() {
  return useColorModeValue("#1a202c", "#ffffff");
}

export function ModalBg() {
  return useColorModeValue("WhiteAlpha.100", "#111E34");
}
//Visit Module
export function VisitBorder() {
  return useColorModeValue("#E1E1E1", "#494D57");
}
export function VisitBG() {
  return useColorModeValue("transparent", "#1A1C1E");
}

export function VisitHover() {
  return useColorModeValue("blue.50", "gray.800");
}

export function PageBackground() {
  return useColorModeValue("#F5F5F5", "#000000");
}

export function AccordionBackground() {
  return useColorModeValue("gray.300", "#001129");
}
export function WidgetBackground() {
  return useColorModeValue("#fff", "#001129");
}
export function WidgetBackgroundInverted() {
  return useColorModeValue("#001129", "#fff");
}
export function TrHover() {
  return useColorModeValue("gray.200", "gray.800");
}

// Chat
export function ChatGrayBackground() {
  return useColorModeValue("#F5F5F5", "#111E34");
}
export function ChatLightBlack() {
  return useColorModeValue("#000", "#fff");
}
export function ChatHover() {
  return useColorModeValue("#E1E1E1", "#3C4770");
}
export function ChatHDividerLine() {
  return useColorModeValue("#E1E1E1", "#3C4770");
}
export function ChatDividerBrightLine() {
  return useColorModeValue("#1A1C1E", "#AFB6C6");
}
export function ChatMessagesBackground() {
  return useColorModeValue("#FDFDFD", "#001129");
}
export function ChatMyMessageBackground() {
  return useColorModeValue("#F1F5FB", "#111E34");
}
export function ChatMyMessageFontColor() {
  return useColorModeValue("##1A1C1E", "#AFB6C6");
}
